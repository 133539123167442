import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout-default";
import Hero from "../components/hero";
import Breadcrumbs from "../components/breadcrumbs";
import RfLatin from "../images/latin.jpg";
import RfReno from "../images/reno.jpg";
import BravoFest from "../images/LABravoFest.jpg";

import "../styles/mindbody.scss";
const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "Teams",
    link: "Teams",
  },
  {
    label: "Performance & Events",
    link: "performance-events",
  },
];

const Performance = () => {
  return (
    <>
      <Layout
        title="Best Studio Rentals in Orange County | RF Dance"
        description="Discover the best studio rentals in Orange County and unlock a world of possibilities for your next project. Book now and start turning your vision into reality."
        pathname="account"
        noHero="no-hero"
      >
        <Hero titleRed={`RF`} titleWhite={`Performance & Events`} />
        <div className="content-block all-performance">
          <div className="container">
            <Breadcrumbs crumbs={crumbs} />
            <div className="news_area">
              <div className="events_performance">
                <div className="news_item">
                  <div className="events_performance_img">
                    <div className="common-performance_img">
                      <img src={RfLatin} alt="Reno Latin Dance Fest 2025" />
                    </div>
                    <div className="common-performance_img">
                      <img src={RfReno} alt="Reno Latin Dance Fest 2025" />
                    </div>
                  </div>
                  <div className="news_content">
                    <h1 className="news_heading">Reno Latin Dance Fest 2025</h1>
                    <h3 className="news_heading">
                      Don't Miss the 2025 Reno Latin Dance Fest with Exclusive
                      RF Dance Team Discount!
                    </h3>
                    <p>
                      Get ready for an incredible dance experience at the Reno
                      Latin Dance Fest from January 9-12, 2025! Here's your
                      chance to secure your Full Festival Team Discount pass.
                    </p>
                    <h3 className="news_heading">
                      How to Buy Your Discounted Pass:
                    </h3>
                    <ol>
                      <li>
                        <strong>Click on the website link : </strong>
                        <Link to="https://2025renolatindancefest.eventbrite.com/">
                          Reno Latin Dance Fest
                        </Link>
                      </li>
                      <li>
                        <strong>
                          Click on the red “reserve a spot” button.
                        </strong>
                      </li>
                      <li>
                        <strong>Copy/Paste Promo Code: </strong>
                        MZRFRLDFteam2025
                      </li>
                      <li>
                        <strong>
                          Look for the “TEAM DISCOUNT - FULL FESTIVAL | Reg
                          Price $350” pass
                        </strong>{" "}
                        to appear below the promo code after it's entered.
                      </li>
                      <li>
                        <strong>
                          If you don’t see the pass, contact us ASAP!
                        </strong>
                      </li>
                    </ol>
                    <p>
                      Don’t miss out on this fantastic opportunity to dance,
                      learn, and celebrate with fellow dance enthusiasts! Visit{" "}
                      <Link to="http://www.renolatindancefest.com/">
                        www.RenoLatinDanceFest.com
                      </Link>
                      for more information.
                    </p>
                    <p>
                      Get your tickets now and join us for the best dance
                      festival of the year!
                    </p>
                    <div className="register_link">
                      <Link to="https://2025renolatindancefest.eventbrite.com/">
                        Link
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-e">
              <div className="p">
                <h3>English:</h3>
                <div className="news_areaa">
                  <div className="events_performance">
                    <div className="news_item">
                      <div className="events_performance_img">
                        <img src={BravoFest} alt="LA Bravo Fest" />
                      </div>
                      <div className="news_content">
                        <h1 className="news_heading">LA Bravo Fest</h1>
                        <p>
                          Join us at the La Bravo Fest, November 28th- December
                          3rd, 2024, featuring Salsa & Bachata. Take advantage
                          of the opportunity to learn from instructors from all
                          over the world, watch performances, and social dance
                          all night!
                        </p>
                        <p>LA Bravo Fest Pass Dicount:</p>
                        <ol>
                          <li>Click Link</li>
                          <li>Click on the Dream Pass</li>
                          <li>Use Promocode: “RFDance”</li>
                        </ol>
                        <div className="register_link">
                          <Link to="https://labravofest.com/registration/">
                            Link
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p">
                <h3>Spanish:</h3>
                <div className="news_areaa">
                  <div className="events_performance">
                    <div className="news_item">
                      <div className="events_performance_img">
                        <img src={BravoFest} alt="LA Bravo Fest" />
                      </div>
                      <div className="news_content">
                        <h1 className="news_heading">LA Bravo Fest</h1>
                        <p>
                          ¡Únete a nosotros en La Bravo Fest del 28 de noviembre
                          al 3 de diciembre de 2024, con Salsa y Bachata!
                          Aprovecha la oportunidad de aprender de instructores
                          de todo el mundo, disfrutar de presentaciones y bailar
                          socialmente toda la noche.
                        </p>
                        <p>Descuento para el pase de La Bravo Fest:</p>
                        <ol>
                          <li>Haz clic en el enlace</li>
                          <li>Selecciona el Dream Pass</li>
                          <li>Usa el código promocional: “RFDance”</li>
                        </ol>
                        <div className="register_link">
                          <Link to="https://labravofest.com/registration/">
                            Link
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Performance;
